
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: String,
    description: String,
    route: String
  }
})
export default class SearchVisitCard extends Vue {
  id = ''
  title!: string
  description!: string
  route!: string

  submit (): void {
    this.$router.push({ name: this.route, params: { alias: this.id } })
  }
}
