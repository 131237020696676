import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white shadow sm:rounded-lg" }
const _hoisted_2 = { class: "px-4 py-5 sm:p-6 flex flex-col flex-grow h-full" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-2 max-w-xl text-sm text-gray-500 flex flex-col flex-grow" }
const _hoisted_5 = { class: "flex-grow" }
const _hoisted_6 = { class: "w-full sm:max-w-xs" }
const _hoisted_7 = {
  for: "email",
  class: "sr-only"
}
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  type: "submit",
  class: "mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("form", {
        class: "mt-5 sm:flex sm:items-center",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('SearchVisitCard.PatientAlias')) + ": ", 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.id) = $event)),
            type: "text",
            name: "patient_id",
            id: "patient_id",
            class: "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md",
            placeholder: _ctx.$t('SearchVisitCard.PatientAliasPlaceholder')
          }, null, 8, _hoisted_8), [
            [_vModelText, _ctx.id]
          ])
        ]),
        _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('SearchVisitCard.Next')), 1)
      ], 32)
    ])
  ]))
}