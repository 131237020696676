import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center w-full" }
const _hoisted_2 = { class: "flex flex-col lg:flex-row gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchVisitCard = _resolveComponent("SearchVisitCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchVisitCard, {
        title: _ctx.$t('Dashboard.FirstVisitTitle'),
        description: _ctx.$t('Dashboard.FirstVisitDescription'),
        route: "PatientData"
      }, null, 8, ["title", "description"]),
      _createVNode(_component_SearchVisitCard, {
        title: _ctx.$t('Dashboard.SecondVisitTitle'),
        description: _ctx.$t('Dashboard.SecondVisitDescription'),
        route: "TrackingForm"
      }, null, 8, ["title", "description"]),
      _createVNode(_component_SearchVisitCard, {
        title: _ctx.$t('Dashboard.ThirdVisitTitle'),
        description: _ctx.$t('Dashboard.ThirdVisitDescription'),
        route: "TrackTreatmentResult"
      }, null, 8, ["title", "description"]),
      _createVNode(_component_SearchVisitCard, {
        title: _ctx.$t('Dashboard.FinalVisitTitle'),
        description: _ctx.$t('Dashboard.FinalVisitDescription'),
        route: "TreatmentResult"
      }, null, 8, ["title", "description"])
    ])
  ]))
}