
import { Options, Vue } from 'vue-class-component'
import SearchVisitCard from '@/components/SearchVisitCard.vue' // @ is an alias to /src

@Options({
  components: {
    SearchVisitCard
  }
})
export default class Dashboard extends Vue {}
